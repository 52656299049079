.Feedback {
    font-size: 12px;
}

.Feedback.valid {
    color: var(--success-color);
}

.Feedback.invalid {
    color: var(--danger-color);
}