@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,400;1,500&display=swap');

:root {
  --primary-color: #14837B;
  --secondary-color: #383838;
  --nav-link-color: #696969;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --warning-color: #ffc107;
  --info-color: #17a2b8;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body, html { 
  width: 100%;
  height: 100%;
}

body {
  background: #E5E5E5;
  font-family: "Ubuntu", sans-serif;
}

button {
  font-family: "Ubuntu", sans-serif;
}

a {
  text-decoration: none;
}

.disabled {
  background: #ccc;
}

.cursor-pointer {
  cursor: pointer;
}

.mainContent {
  padding-top: 100px;
  min-height: 600px;
}

.headingXX {
  font-size: 52.8px !important;
}

.headingXl {
  font-size: 36.8px !important;
}

.headingLg {
  font-size: 32px !important;
  line-height: 1.5 ;
}

.headingMd {
  font-size: 27.2px !important;
}

.headingSm {
  font-size: 19.2px !important;
}

.textXX {
  font-size: 24px !important;
}

.textXl {
  font-size: 20.8px !important;
  line-height: 2;
}

.textLg {
  font-size: 17.6px !important;
  line-height: 2;
}

.textMd {
  font-size: 16px !important;
  line-height: 2;
}

.hidden {
  display: none;
}

.textSm {
  font-size: 14.4px !important;
  line-height: 2;
}

.headingLg {
  font-size: 32px !important;
}

.c-primary {
  color: var(--primary-color);
}
.c-white {
  color: #fff;
}

.c-yellow {
  color: #FFC200;
}

.c-dark {
  color: #4B4B4B;
}

.c-text {
  color: #707070;
}

.text-weight-bold {
  font-weight: bold;
}

.text-weight-normal {
  font-weight: normal;
}

.text-weight-light {
  font-weight: 300;
}

.text-weight-semibold {
  font-weight: 500;
}

.text-weight-extrabold {
  font-weight: 700;
}

.bg-primary {
  background: var(--primary-color) !important;
}

.defaultLeftContentHorizontalPadding {
  padding-left: 120px;
  padding-right: 50px;
}

.leftSideDivider {
  width: 250px;
  margin-left: -120px;
}



@media screen and (min-width: 1400px) {
  .bootstrap-wrapper .container {
      max-width: 1320px;
  }
}

@media screen and (max-width: 992px) {
  .defaultLeftContentHorizontalPadding {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .headingXX {
    font-size: 48px !important;
  }
  
  .headingXl {
    font-size: 32px !important;
  }
  
  .headingLg {
    font-size: 28.8px !important;
    line-height: 1.4;
  }
  
  .headingMd {
    font-size: 24px;
  }
  
  .headingSm {
    font-size: 17.6px !important;
  }

  .textXl {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 576px) {
  .textXX {
    font-size: 20.8px !important;
  }

  .headingXX {
    font-size: 43.2px !important;
  }
  
  .headingXl {
    font-size: 28.8px !important;
  }
  
  .headingLg {
    font-size: 24px !important;
    line-height: 1.4;
  }
  
  .headingMd {
    font-size: 20.8px !important;
  }

  .headingSm {
    font-size: 16px !important;
  }

}