.CompanySelectorItem {
    display: flex;
    padding: 10px;
    border-radius: 4px;
    background: #ccc;
    font-size: 14px;
}

.CompanySelectorItem:hover {
    text-decoration: underline;
}

.CompanySelectorItem .companyName, .CompanySelectorItem .comanyCnpj {
    flex: 1;
}