.flag {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin: 10px;
    filter: grayscale(1);
}

.flag:hover {
    filter: grayscale(0.5);
}
  
.selected {
    filter: grayscale(0);
}
  
.selected:hover {
    filter: grayscale(0);
}