.FileInput {
    position: relative;
    overflow: hidden;
}

.FileInput .Feedback {
    font-size: 10px;
    text-align: center;
}

.FileInput label .inputLabel {
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
}

.FileInput input[type="file"] {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
}

.FileInput label {
    padding: 5px 10px;
    font-weight: bold;
    background: var(--info-color);
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.FileInput label.isInvalid {
    background: var(--danger-color);
}