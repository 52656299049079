.ActionButton {
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 3px;
    cursor: pointer;
    background: transparent;
}

.ActionButton:disabled {
    background: #707070 !important;
}

.ActionButton.btnPrimary {
    background: var(--primary-color);
}

.ActionButton.btnSecondary {
    background: var(--secondary-color);
}

.ActionButton.btnDanger {
    background: var(--danger-color);
}

.ActionButton.btnWarning {
    background: var(--warning-color);
}

.ActionButton.btnInfo {
    background: var(--info-color);
}

.ActionButton.btnSuccess {
    background: var(--success-color);
}