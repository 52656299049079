.Header {
    background: #fff;
    height: 100px;
    transform: translateY(0);
    transition: transform .3s ease;
    position: relative;
    z-index: 1000000;
}

.Header.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, .01);
}

.Header.headerHidden {
    transform: translateY(-100px);
}

.Header .brand {
    position: relative;
    width: 150px;
}

.Header .brand img {
    width: 100%;
}


.Header .btnLogin {
    margin-left: 20px;
}

.Header .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.navbar {
    display: flex;
}

.navbar .navbarNav {
    border-bottom: 1px solid #707070;
}

.navbarNavLink {
    display: inline-block;
    padding: 15px 20px;
    font-weight: bold;
    color: #696969;
    transition: color .3s ease;
}

.navbarNavLink:hover {
    color: var(--primary-color);
}

.navbarNavLink.active {
    color: var(--primary-color);
}

@media screen and (max-width: 1199.8px) {
    .navbar #menu, .Header .btnLogin {
        display: none !important;
    }
    
    .Header .navbar .UserProfile {
        display: none;
    }
    
}