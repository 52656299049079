.Tab {
    padding: 20px 10px 15px 10px;
    color: var(--primary-color);
    border-bottom: 8px solid transparent;
    font-weight: 500;
    cursor: pointer;
    transition: border-color .3s ease;
    display: inline-flex;
    align-items: center;
}

.Tab.active {
    border-color: 8px solid var(--primary-color);
}