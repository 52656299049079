.Button {
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 1rem;
    transition: background .3s ease, color .3s ease;
}

.btnPrimary {
    background: var(--primary-color);
}

.Button:disabled {
    background-color: #707070;
}

.btnOutlinePrimary {
    background: transparent;
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
}

.btnOutlinePrimary:hover {
    background: var(--primary-color);
    color: #fff;
}

.btnSecondary {
    background: var(--secondary-color);
}

.btnMaterial {
    background: transparent;
    color: var(--primary-color);
}

.btnPrimary
.btnSecondary,
.btnDanger,
.btnSuccess,
.btnWarning,
.btnInfo {
    color: #fff;
}

.btnDanger {
    background: var(--danger-color);
}

.btnSuccess {
    background: var(--success-color);
}

.btnWarning {
    background: var(--warning-color);
}

.btnInfo {
    background: var(--info-color);
}

.btn

.btnXs {
    font-size: .7rem;
}

.btnSm {
    font-size: .9rem;
}

.btnMd {
    font-size: 1rem;
}

.btnLg {
    font-size: 1.1rem;
    padding: 15px 25px;
}

.btnXl {
    font-size: 1.2rem;
}

.btnRoundedXs {
    border-radius: 3px;
}

.btnRoundedSm {
    border-radius: 6px;
}

.btnRoundedMd {
    border-radius: 10px;
}

.btnRoundedLg {
    border-radius: 15px;
}

.btnRoundedXl {
    border-radius: 20px;
}

.btnRoundedFull {
    border-radius: 250px;
}