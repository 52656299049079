.Badge {
    font-size: 10px;
    min-height: 16px;
    min-width: 16px;
    border-radius: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: background .3s ease;
}


.badgePrimary,
.badgeSecondary,
.badgeDanger,
.badgeWarning,
.badgeSuccess,
.badgeInfo {
    color: #fff;
}

.badgePrimary {
    background: var(--primary-color);
}

.badgeSecondary {
    background: var(--secondary-color);
}

.badgeDanger {
    background: var(--danger-color);
}

.badgeWarning {
    background: var(--warning-color);
}

.badgeSuccess {
    background: var(--success-color);
}

.badgeInfo {
    background: var(--info-color);
}