.ButtonLink {
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
}

.btnPrimary {
    background: var(--primary-color);
    color: #fff;
}

.btnSecondary {
    background: var(--secondary-color);
    color: #fff;
}

.btnLight {
    background: white;
    color: var(--primary-color);
}

.btnXs {
    font-size: .7rem;
}

.btnSm {
    font-size: .9rem;
}

.btnMd {
    font-size: 1rem;
}

.btnLg {
    font-size: 1.1rem;
    padding: 15px 25px;
}

.btnXl {
    font-size: 1.2rem;
    padding: 20px 30px;
}

.btnRoundedXs {
    border-radius: 3px;
}

.btnRoundedSm {
    border-radius: 6px;
}

.btnRoundedMd {
    border-radius: 10px;
}

.btnRoundedLg {
    border-radius: 15px;
}

.btnRoundedXl {
    border-radius: 20px;
}

.btnRoundedFull {
    border-radius: 250px;
}