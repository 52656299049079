.TextField {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    background: #fff;
    height: 54px;
    border: 2px solid #707070;
    border-radius: 5px;
}

.TextField input:disabled {
    background-color: #ccc;
}

.TextField.isInvalid label {
    color: var(--danger-color) !important;
}

.TextField label {
    position: absolute;
    font-size: 1rem;
    left: 10px;
    top: calc(25px - .5rem);
    transition: top .3s ease, font-size .3s ease;
    color: #4E4E4E38;
    z-index:  1;
}

.TextField.focused label {
    top: 10px;
    font-size: .7rem;
    color: #4E4E4EBC;
}

.TextField input {
    background: transparent;
    outline: none;
    border: none;
    padding: 18px 10px 10px 10px;
    height: 100%;
    font-size: 1rem;
    display: block;
    width: 100%;
    color: #333;
    z-index: 2;
}