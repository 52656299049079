.UserProfile {
    display: flex;
    height: 100%;
    align-items: center;
}

.UserProfile .userAccount {
    display: inline-flex;
    flex-direction: column;
    margin-right: 50px;
    color: #707070;
    font-size: 14px;
}


.UserProfile .userAccount .userIcon {
    margin-bottom: 6px;
}