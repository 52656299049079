.ServiceLink {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.ServiceLink .linkIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    border: 1px solid #707070;
    background: #fff;
    margin-bottom: 5px;
}

.ServiceLink .linkTitle {
    font-size: 12px;
    color: #707070;
    text-align: center;
}