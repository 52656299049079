.Table {
    background: #fff;
    border-radius: 15px;
    border: 1px solid #707070;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
}

.Table .tableContainer {
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background: #525252;
}

.Table .tableBorder {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    background: #ccc;
    
}

.Table .tableWrapper {
    background: #EDEDED;
    min-height: 200px;
    overflow-x: auto;
}

.Table .sortIcon {
    margin-left: 10px;
}

.Table table {
    width: 100%;
    min-width: 1024px;
    border-collapse: collapse;
}

.Table table th {  
    background: #CCCCCC;
    font-weight: normal;
    text-align: left;
}

.Table table th {
    padding: 5px 15px;
}

.Table table th, .Table table td {
    font-size: 18px;
    color: #525252;
    height: 40px;
    border: 1px solid #707070;
    position: relative;
}

.Table table td {
    max-width: 250px;
    position: relative;
}

.Table table tr.active td {
    background: #fff;
}