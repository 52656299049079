.OpenInvoice {
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.OpenInvoice .row {
    padding-left: 40px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.OpenInvoice .row, .OpenInvoice .left, .OpenInvoice .right {
    margin: 0;
}

.OpenInvoice .right {
    border-right: 10px solid var(--primary-color);
}

.OpenInvoice .openInvoiceText {
    color: #FFC200;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.OpenInvoice .invoiceValue {
    font-size: 32px;
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: 15px;
}

.OpenInvoice .left {
    border-right: 1px solid #e8e8e8;
}

.OpenInvoice .invoiceDetails {
    font-size: 15px;
    font-weight: 500;
    color: #707070;
    line-height: 1.8;
    margin-bottom: 20px;
}

.OpenInvoice .invoiceBtn {
    width: 60%;
    height: 54px;
    font-weight: 500;
}

.OpenInvoice .invoiceBtn:not(:last-child) {
    margin-bottom: 20px;
}

.OpenInvoice .showDetailedInvoice {
    font-weight: bold;
    color: #707070;
    font-size: 17px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.OpenInvoice .showDetailedInvoice a {
    color: #707070;
}

.plusIndicator {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #707070;
    font-size: 19px;
    line-height: 0;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    margin-right: 10px;
}