.PasswordField {
    display: flex;
}

.PasswordField .TextField {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}

.PasswordField button {
    width: 54px;
    height: 54px;
    border: 2px solid #707070;
    border-left: none;
    background: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
}

.PasswordField button:disabled {
    background: #ccc;
}
