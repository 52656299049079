.CellSelect {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #707070;
    position: absolute;
    left: 0;
    top:0;
}

.CellSelect select {
    background: transparent;
    font-size: 18px;
    color: #525252;
    padding: 0 5px;
    border: none;
    border-radius: 0;
    width: 100%;
    height: 100%;
}

.CellSelect .Feedback {
    position: absolute;
    right: 5px;
    bottom: 0;
}

.CellSelect .Feedback {
    font-size: 10px;
}

.CellSelect select.isInvalid {
    outline: 2px solid var(--danger-color) !important;
}

.CellSelect select:focus {
    outline: 2px solid var(--primary-color);
    top: 0;
    background: #fff;
}