.LogoutButton {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #707070;
    font-size: 14px;
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    background: transparent;
}

.LogoutButton .logoutIcon {
    margin-bottom: 6px;
}